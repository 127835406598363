/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/stylesheets/grid.css'
import './src/stylesheets/header.css'
import './src/stylesheets/portraitDetails.css'
import './src/stylesheets/photoTips.css'
import './src/stylesheets/contact.css'
